<template>
  <div class="card card-monitor">
    <div class="card-body d-flex flex-row justify-content-between align-items-center">
      <div class="card-monitor-name-wrapper">
        <div style="width: 380px">
          <p class="card-monitor-name">
            {{ name || url }}
          </p>
          <p class="card-monitor-url">
            {{ url }}
          </p>
        </div>
        <base-toggle
          v-model="enableWebsite"
          class="even-smaller cursor-pointer"
          @input="$emit('updateWebsite', enableWebsite, id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseToggle from '@/components/Base/BaseToggle.vue'

export default {
  name: 'WebsiteCard',
  components: { BaseToggle },
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    id: {
      type: Number
    },
    selected: {
      type: Boolean
    }
  },
  data () {
    return {
      enableWebsite: this.$props.selected
    }
  }
}
</script>

<style scoped lang="scss">
.card-monitor {
  & > .card-body {
    padding: 18px 20px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #F6F6F6;
    min-height: auto;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px 0 #19221D0D;

    &:hover {
      background: rgba(140, 20, 252, 0.1) !important;
      border-color: #654be3 !important;
      cursor: pointer;
    }
  }

  &-name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  &-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: 0.05px;
    color: #1B1823;
    padding-bottom: 5px;
  }

  &-url {
    font-size: 11px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: 0.02px;
    color: #939394;
  }
  .BaseToggle {
    &.toggle--size-small.even-smaller {
      width: 25px !important;
    }
  }

  .even-smaller::v-deep {
    margin: 0 5px 0 0;

    input[type="checkbox"] {
      ~ label .toggle__switch {
        height: 12px !important;
        max-width: 20px !important;

        &:after {
          height: 8px;
          width: 8px;
          position: absolute;
          top: 50%;
          left: 3px;
          transform: translateY(-50%);
        }
      }

      &[disabled] ~ label {
        color: rgba(#777, 0.5);
      }
      &:focus ~ label, &:hover ~ label {
        .toggle__switch {
          background-color: #777;
          &:after {
            color: darken(#777, 10%);
          }
        }
      }
      &:hover ~label { color: darken(#777, 5%); }
      &:checked {
        ~ label {
          &:hover { color: darken(map-get($colors, purple-1), 3%); }

          .toggle__switch {
            background-color: map-get($colors, purple-1);
            &:after {
              color: darken(map-get($colors, purple-1), 5%);
              height: 8px;
              width: 8px;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate(10px, -50%) !important;
              transition: 0.3s;
            }
          }
        }

        &:focus, &:hover {
          ~ label {
            .toggle__switch {
              background-color: map-get($colors, purple-1);
              &:after {
                color: darken(map-get($colors, purple-1), 10%);
              }
            }
          }
        }
      }
    }

    label {
      .toggle__switch {
        transition: background-color 0.3s cubic-bezier(0,1,0.5,1);
        background: lighten(#777, 5%);
        font-weight: 600;
        &:before { color: rgba(white, 0.5) }
        &:after {
          transition: transform .25s,width .25s;
          transition-timing-function: cubic-bezier(.23,2.01,.43,.66);
          color: #777;
        }
      }
    }
  }

}
</style>
